import { keyPairData } from ".";
import { ManualHandlingDecisions } from "../../components/groupedComponents/ManualHandlingTest";
import { notTestedSelectKeys, notTestedSelectKeysFriendly } from "../../components/modals/NotTestedModal";
import { dataStorePageKeys, dataStorePageKeysFriendly } from "../../stores/dataStore";
import { manualHandlingDataKeys } from "../../views/app/PefaFinalisation/previewResultsDataKeys";

export default class handlingResults {
  public static formatForSubmission(
    existingData: any,
    handlingResults: {
      [key: string]: any;
    },
    dataKey: dataStorePageKeys,
    testDemands: any
  ): keyPairData[] {
    const formattedData: keyPairData[] = existingData;
    const trials: keyPairData[] = [];
    const observations: keyPairData[] = [];

    let observationKeysList: any[] = [];
    let discomfortSetKey: string | undefined = undefined;
    let discomfort: any = {};
    let safeMaxDataKeys: string[] = [];

    manualHandlingDataKeys.forEach((manualHandlingDataKey) => {
      if (manualHandlingDataKey.dataKey === dataKey) {
        observationKeysList = manualHandlingDataKey.assessorObservationKeys;
        discomfortSetKey = manualHandlingDataKey.discomfortSetKey;
        return;
      }
    });

    //determine the safe max limit datakeys for each page.
    switch (dataKey) {
      case dataStorePageKeys.MH_BENCH_TO_FLOOR: {
        safeMaxDataKeys = ["safeMaxFloor", "safeMaxBench"];
        break;
      }
      case dataStorePageKeys.MH_BENCH_TO_BENCH: {
        safeMaxDataKeys = ["safeMaxBench"];
        break;
      }
      case dataStorePageKeys.MH_BENCH_TO_SHOULDER: {
        safeMaxDataKeys = ["safeMaxShoulder"];
        break;
      }
      case dataStorePageKeys.MH_BENCH_TO_ABOVE: {
        safeMaxDataKeys = ["safeMaxAboveShoulder"];
        break;
      }
      case dataStorePageKeys.MH_BILATERAL: {
        safeMaxDataKeys = ["safeBilateral"];
        break;
      }
      case dataStorePageKeys.MH_SINGLE_L: {
        safeMaxDataKeys = ["safeSingleL"];
        break;
      }
      case dataStorePageKeys.MH_SINGLE_R: {
        safeMaxDataKeys = ["safeSingleR"];
        break;
      }
    }

    //Trials
    if (!handlingResults["notTestedReason"]) {
      handlingResults.attempts.forEach((attempt: any) => {
        try {
          let trial: keyPairData = {
            weight: handlingResults[`weight_${attempt.attempt}`],
            heartRate: handlingResults[`bpm_${attempt.attempt}`],
            comment: handlingResults[`comment_${attempt.attempt}`],
            safe: handlingResults[`safeRepeats_${attempt.attempt}`] === "YES" ? true : false,
            decision: ManualHandlingDecisions[handlingResults[`decision_${attempt.attempt}`]]?.value,
          };

          const additionalComments = [];

          //Add the stopped message and reason to the additionalComments list if the decision to stop was made.
          if (
            handlingResults[`decision_${attempt.attempt}`] === ManualHandlingDecisions.PARTICIPANT_STOPPED.key ||
            handlingResults[`decision_${attempt.attempt}`] === ManualHandlingDecisions.ASSESSOR_STOPPED.key
          ) {
            // additionalComments.push(handlingResults["stoppedReason"]);
            // additionalComments.push(handlingResults["stoppedMessage"]);
          }

          //Add the rest time to the trial data list if the participant took a rest during the test.
          if (handlingResults[`decision_${attempt.attempt}`] === ManualHandlingDecisions.REST_TO_LOWER_HR.key) {
            trial = {
              ...trial,
              recoveryTime: handlingResults["recoveryTimeFormatted"],
            };
          }

          if (additionalComments.length > 0) {
            trial = {
              ...trial,
              additionalComments,
            };
          }

          trials.push(trial);
        } catch (error) {
          /* Ignore and try next */
        }
      });
    }

    //Observations
    observationKeysList.forEach((observationKeys) => {
      let value = "";
      let extraInfo: string | undefined = undefined;

      observationKeys.valueKeys.forEach((valueObj: keyPairData) => {
        if (handlingResults[valueObj.key]) {
          value = valueObj.friendlyName;
          return;
        }
      });

      if (!!observationKeys.notes.notesField) {
        extraInfo = handlingResults[observationKeys.notes.notesField];
      }

      let observation: keyPairData = {
        name: observationKeys.friendlyName,
        value: value,
      };

      if (!!extraInfo) {
        observation = { ...observation, extraInfo };
      }

      observations.push(observation);
    });

    //Discomfort
    if (handlingResults[`${discomfortSetKey}_reportedDiscomfort`]) {
      let intensity = "";
      let status = "";
      const type: string[] = [];

      //prepare the intensity
      if (!!handlingResults[`${discomfortSetKey}_intensityVas`]) {
        intensity = handlingResults[`${discomfortSetKey}_intensityVas`];
      } else if (handlingResults[`${discomfortSetKey}_intensityMild`]) {
        intensity = "Mild";
      } else if (handlingResults[`${discomfortSetKey}_intensityMod`]) {
        intensity = "Moderate";
      } else if (handlingResults[`${discomfortSetKey}_intensitySevere`]) {
        intensity = "Severe";
      }

      //prepare the status
      if (handlingResults[`${discomfortSetKey}_discomfortResolvedImmediately`]) {
        status = "Resolved immediately";
      } else if (handlingResults[`${discomfortSetKey}_discomfortPersisting`]) {
        status = "Persisting";
      } else if (handlingResults[`${discomfortSetKey}_discomfortResolvedWithRest`]) {
        status = "Resolved with rest";
      } else if (handlingResults[`${discomfortSetKey}_discomfortIncreasing`]) {
        status = "Increasing";
      }

      //prepare the type
      if (handlingResults[`${discomfortSetKey}_discomfortTypeAche`]) {
        type.push("Ache");
      } else if (handlingResults[`${discomfortSetKey}_discomfortTypeSharp`]) {
        type.push("Sharp");
      } else if (handlingResults[`${discomfortSetKey}_discomfortTypeBurning`]) {
        type.push("Burning");
      } else if (handlingResults[`${discomfortSetKey}_discomfortTypeTightness`]) {
        type.push("Tightness");
      } else if (handlingResults[`${discomfortSetKey}_discomfortTypePulling`]) {
        type.push("Pulling");
      } else if (handlingResults[`${discomfortSetKey}_discomfortTypeOther`]) {
        type.push("Other");
      }

      discomfort = {
        location: handlingResults[`${discomfortSetKey}_location`],
        intensity,
        type,
        status,
      };
    }

    let handlingResult: keyPairData = {};

    if (!handlingResults["notTestedReason"]) {
      handlingResult = {
        testID: testDemands?.testID || 0,
        tested: true,
        name: dataStorePageKeysFriendly[dataKey],

        PreRecoveryHeartRate: handlingResults["preTestHeartRate"],
        RecoveryTime: handlingResults["preTestRecoveryTime"],

        trials,

        observations,
        discomfort,
        weightAchieved: handlingResults["testWeight"],
      };

      for (let i = 0, len = safeMaxDataKeys.length; i < len; i++) {
        handlingResult = {
          ...handlingResult,
          [`safeMax${i}`]: handlingResults[safeMaxDataKeys[i]],
        };
      }
    } else {
      handlingResult = {
        testID: testDemands?.testID || 0,
        tested: false,
        notTestedReason: notTestedSelectKeysFriendly[handlingResults["notTestedReason"] as notTestedSelectKeys],
        comment: handlingResults["notTestedNotes"],
        name: dataStorePageKeysFriendly[dataKey],
        discomfort,
        ratingResult: "NT",
      };
    }

    formattedData.push(handlingResult);

    return formattedData;
  }
}
