import { keyPairData, resultTimeKeys } from ".";
import { notTestedSelectKeys, notTestedSelectKeysFriendly } from "../../components/modals/NotTestedModal";
import { dataStorePageKeys, dataStorePageKeysFriendly } from "../../stores/dataStore";
import { dynamicToleranceDataKeys, posturalToleranceDataKeys } from "../../views/app/PefaFinalisation/previewResultsDataKeys";

export default class toleranceResults {
  public static formatForSubmission(
    existingData: any,
    toleranceResults: {
      [key: string]: any;
    },
    dataKey: dataStorePageKeys,
    testDemands: any
  ): keyPairData[] {
    const formattedData: keyPairData[] = existingData;

    const results: keyPairData[] = [];
    const observations: keyPairData[] = [];
    let discomfort: keyPairData = {};

    let observationKeysList: any[] = [];
    let discomfortSetKey: string | undefined = undefined;

    switch (dataKey) {
      case dataStorePageKeys.PT_FORWARD:
      case dataStorePageKeys.PT_ABOVE:
      case dataStorePageKeys.PT_STOOP:
      case dataStorePageKeys.PT_SQUAT:
      case dataStorePageKeys.PT_KNEEL: {
        posturalToleranceDataKeys.forEach((posturalToleranceDataKey) => {
          if (posturalToleranceDataKey.dataKey === dataKey) {
            observationKeysList = posturalToleranceDataKey.assessorObservationKeys;
            discomfortSetKey = posturalToleranceDataKey.discomfortSetKey;
            return;
          }
        });
        break;
      }
      case dataStorePageKeys.DT_LADDER:
      case dataStorePageKeys.DT_SIDE:
      case dataStorePageKeys.DT_CRAWL: {
        dynamicToleranceDataKeys.forEach((dynamicToleranceDataKey) => {
          if (dynamicToleranceDataKey.dataKey === dataKey) {
            observationKeysList = dynamicToleranceDataKey.assessorObservationKeys;
            discomfortSetKey = dynamicToleranceDataKey.discomfortSetKey;
            return;
          }
        });
        break;
      }
    }

    //Recordings
    resultTimeKeys.forEach((timeKey: keyPairData) => {
      try {
        // check if key exists
        if (`Bpm${timeKey.key}` in toleranceResults) {
          const recording = {
            time: timeKey.value,
            heartRate: toleranceResults[`Bpm${timeKey.key}`],
            comment: toleranceResults[`Comment${timeKey.key}`],
          };

          results.push(recording);
        }
      } catch (err) {
        /* ignore an try next key */
      }
    });

    //Observations
    observationKeysList.forEach((observationKeys) => {
      let value = "";
      let extraInfo: string | undefined = undefined;

      observationKeys.valueKeys.forEach((valueObj: keyPairData) => {
        if (toleranceResults[valueObj.key]) {
          value = valueObj.friendlyName;
          return;
        }
      });

      if (!!observationKeys.notes.notesField) {
        extraInfo = toleranceResults[observationKeys.notes.notesField];
      }

      let observation: keyPairData = {
        name: observationKeys.friendlyName,
        value: value,
      };

      if (!!extraInfo) {
        observation = { ...observation, extraInfo };
      }

      observations.push(observation);
    });

    //Discomfort
    if (toleranceResults[`${discomfortSetKey}_reportedDiscomfort`]) {
      let intensity = "";
      let status = "";
      const type: string[] = [];

      //prepare the intensity
      if (!!toleranceResults[`${discomfortSetKey}_intensityVas`]) {
        intensity = toleranceResults[`${discomfortSetKey}_intensityVas`];
      } else if (toleranceResults[`${discomfortSetKey}_intensityMild`]) {
        intensity = "Mild";
      } else if (toleranceResults[`${discomfortSetKey}_intensityMod`]) {
        intensity = "Moderate";
      } else if (toleranceResults[`${discomfortSetKey}_intensitySevere`]) {
        intensity = "Severe";
      }

      //prepare the status
      if (toleranceResults[`${discomfortSetKey}_discomfortResolvedImmediately`]) {
        status = "Resolved immediately";
      } else if (toleranceResults[`${discomfortSetKey}_discomfortPersisting`]) {
        status = "Persisting";
      } else if (toleranceResults[`${discomfortSetKey}_discomfortResolvedWithRest`]) {
        status = "Resolved with rest";
      } else if (toleranceResults[`${discomfortSetKey}_discomfortIncreasing`]) {
        status = "Increasing";
      }

      //prepare the type
      if (toleranceResults[`${discomfortSetKey}_discomfortTypeAche`]) {
        type.push("Ache");
      } else if (toleranceResults[`${discomfortSetKey}_discomfortTypeSharp`]) {
        type.push("Sharp");
      } else if (toleranceResults[`${discomfortSetKey}_discomfortTypeBurning`]) {
        type.push("Burning");
      } else if (toleranceResults[`${discomfortSetKey}_discomfortTypeTightness`]) {
        type.push("Tightness");
      } else if (toleranceResults[`${discomfortSetKey}_discomfortTypePulling`]) {
        type.push("Pulling");
      } else if (toleranceResults[`${discomfortSetKey}_discomfortTypeOther`]) {
        type.push("Other");
      }

      discomfort = {
        location: toleranceResults[`${discomfortSetKey}_location`],
        intensity,
        type,
        status,
      };
    }

    let toleranceResult: keyPairData = {};

    if (!toleranceResults["notTestedReason"]) {
      toleranceResult = {
        testID: testDemands?.testID || 0,
        tested: true,
        name: dataStorePageKeysFriendly[dataKey],
        results,
        observations,
        discomfort,
        ratingResult: toleranceResults["toleranceRating"],
      };
    } else {
      toleranceResult = {
        testID: testDemands?.testID || 0,
        tested: false,
        notTestedReason: notTestedSelectKeysFriendly[toleranceResults["notTestedReason"] as notTestedSelectKeys],
        comment: toleranceResults["notTestedNotes"],
        name: dataStorePageKeysFriendly[dataKey],
        discomfort,
        ratingResult: "NT",
      };
    }

    formattedData.push(toleranceResult);

    return formattedData;
  }
}
